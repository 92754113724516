import {
  Module, VuexAction, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import { Logger } from '../shared/general/logger/Logger'
import { globalLabels, nuxt } from '../shared/general/logger/LogKey'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class GlobalLabels extends VuexModule {
  labels : Record<string, Record<string, string | RichTextElement[]>> = {}

  @VuexMutation
  storeAll (labels : Record<string, Record<string, string | RichTextElement[]>> = {}) {
    this.labels = { ...labels }
  }

  @VuexMutation
  reset () {
    this.labels = {}
  }

  @VuexAction
  async fetchForLanguage (locale : string) : Promise<void> {
    if (this.labels[locale]) return
    const fetchUrl : string = `${process.client ? window.location.origin : 'http://localhost:3000'}/label/${locale}`

    try {
      this.labels[locale] = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }).then((res) => res.json())
    } catch (e) {
      Logger.error(nuxt, globalLabels, `Could not fetch Global Labels for locale ${locale}`, e)
    }
  }

  get label () : Function {
    return (locale : string, label : string) : string | RichTextElement[] => this.labels[locale]?.[label] || ''
  }
}
