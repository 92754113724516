import { Dataset } from 'fsxa-api'
import {
  Module, VuexAction, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'
import Vue from 'vue'
import { notNull } from '../shared/general/services/TypeAssertions'
import { fetchRemoteDatasets } from '../shared/fsxa/services/RemoteDatasetService'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class RemoteDatasetStore extends VuexModule {
  fetchedDatasets : Record<string, Dataset> = {}

  @VuexMutation
  addDataset (dataset : Dataset) : void {
    if (dataset) Vue.set(this.fetchedDatasets, `${dataset.id}.${dataset.locale}`, dataset)
  }

  @VuexMutation
  addDatasets (datasetList : Dataset[]) : void {
    (datasetList || []).forEach((dataset : Dataset) : void => {
      Vue.set(this.fetchedDatasets, `${dataset.id}.${dataset.locale}`, dataset)
    })
  }

  @VuexAction
  async fetchDatasets ({ schema, caasIdentifiers } : { schema : 'global' | 'local', caasIdentifiers : string[] }) : Promise<void> {
    this.context.commit('addDatasets', await fetchRemoteDatasets(schema, caasIdentifiers))
  }

  get getDataset () : (id : string, locale : string) => Dataset | null {
    return (id : string, locale : string) => this.fetchedDatasets[`${id}.${locale}`] || null
  }

  get getDatasets () : (idList : string[], locale : string) => Dataset[] {
    return (idList : string[], locale : string) => idList.map((id : string) => this.fetchedDatasets[`${id}.${locale}`] || null).filter(notNull)
  }
}
