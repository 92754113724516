export const splitBySpace = (input : string, toLowerCase ?: boolean) => input.split(' ')
  .map((str) => (toLowerCase ? str.toLowerCase() : str))

export const getAnchorLinkText = (anchorStartsWith : string, toLowerCase ?: boolean) : string[] => {
  const { hash } = window.location
  if (!hash.includes(anchorStartsWith)) return []
  const anchorTextEncoded = hash.substring(anchorStartsWith.length, hash.length)
  const anchorText = decodeURIComponent(anchorTextEncoded)
  return splitBySpace(anchorText, toLowerCase)
}

let resizeObserver : ResizeObserver

const timer = (hash : string) => setTimeout(() => {
  resizeObserver.unobserve(document.body)

  try {
    const element = document.querySelector(hash)
    if (!element) return

    const yOffset = -100
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

    window.scrollTo({ top: y, behavior: 'smooth' })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('unsupported selector:', hash)
  }
}, 750)

// readd the hash param to trigger scroll again
export const scrollToSection = (hash : string) => {
  if (!hash || !(window.location.hash && hash === window.location.hash)) {
    return
  }

  // start timer to scroll to the section
  let timeout = timer(hash)

  resizeObserver = new ResizeObserver(() => {
    // body height changed -> loading not finished
    // reset the timer
    clearTimeout(timeout)
    timeout = timer(hash)
  })

  resizeObserver.observe(document.body)
}

// Remove all forbidden characters from the parameters
// Allowed characters are defined in the FS description of Anchorlinks
// Currently: A-Z, a-z, 0-9 and _
// It's a safety measure if the editor is too lazy to read
export const formatToCleanParam = (param : string) => param.replace(/[^A-Za-z0-9_]/g, '_').toLowerCase()
