import {
  ComparisonQueryOperatorEnum, Dataset, FetchResponse, FSXAProxyApi,
} from 'fsxa-api'
import { applyPageRefMappingToRemoteDataset } from 'fsxa-pattern-library/src/utils/misc'
import { func, remoteDataset } from '../../general/logger/LogKey'
import Log from '../../general/logger/Logger'
import { TRemoteDatasetIndex, TRemoteDatasetRecord } from '../types/TRemoteDataset'
import { notNull, notUndefined } from '../../general/services/TypeAssertions'
import StoreSingleton from '../../general/services/StoreSingleton'
import fsxaProxyApiLocal from './FsxaProxyApiLocal'
import fsxaProxyApiRemote from './FsxaProxyApiRemote'

const getDatasetsFromStore = (ids : string[], locale : string = StoreSingleton.instance.state.Locale.fsxaLocale)
  : Dataset[] => StoreSingleton.instance.getters['RemoteDatasetStore/getDatasets'](ids, locale)

const getDatasetFromStore = (id : string, locale : string = StoreSingleton.instance.state.Locale.fsxaLocale)
  : Dataset | null => StoreSingleton.instance.getters['RemoteDatasetStore/getDataset'](id, locale)

const getPageRefMappingFromStore = () : Record<string, string> => StoreSingleton.instance.getters['PageRefMapping/get']

const determineDatasetSchema = (remoteDatasetIndex ?: TRemoteDatasetIndex) : 'global' | 'local' | undefined => {
  // we peek at the first entry as we know (currently) all following entries belong to the same schema
  const schema : string | undefined = remoteDatasetIndex?.value?.[0]?.value?.target?.schema
  if (schema?.includes('global')) return 'global'
  if (schema?.includes('local')) return 'local'
  return undefined
}

const getRemoteDatasetIds = (remoteDatasetIndex ?: TRemoteDatasetIndex) : string[] => remoteDatasetIndex?.value
  ?.map((record : TRemoteDatasetRecord) => record.value?.target?.identifier || null)
  ?.filter(notNull) || []

const getRemoteDatasetsFromStore = (remoteDatasetIndex ?: TRemoteDatasetIndex) : Dataset[] => {
  const datasetIds : string[] = getRemoteDatasetIds(remoteDatasetIndex)
  if (!datasetIds.length) return []
  return getDatasetsFromStore(datasetIds)
}

const getCaasIdentifiersNotInStore = (datasetIds : string[], locale : string = StoreSingleton.instance.state.Locale.fsxaLocale)
  : string[] => datasetIds.filter((id : string) => !getDatasetFromStore(id)).map((id : string) : string => `${id}.${locale}`)

// to prevent multiple fetches we store the promises for datasets which are currently being fetched.
const datasetsBeingFetched : Record<string, Promise<void>> = {}

const getOrFetchRemoteDatasets = async (remoteDatasetIndex : TRemoteDatasetIndex | undefined) : Promise<Dataset[]> => {
  const datasetSchema : 'global' | 'local' | undefined = determineDatasetSchema(remoteDatasetIndex)
  const datasetIds : string[] = getRemoteDatasetIds(remoteDatasetIndex)
  if (!datasetIds.length || !datasetSchema) return []
  if (datasetIds.length > 100) {
    Log.warn(remoteDataset, func('getOrFetchRemoteDatasets'), 'more than 100 DatasetIds passed. Max 100 Datasets will be fetched and returned.')
  }

  // in the case where a dataset is still being fetched (multiple fetches trying to fetch same data) we need to wait for previous fetches to complete
  const datasetsBeingProcessedPromises : Promise<void>[] = getCaasIdentifiersNotInStore(datasetIds)
    .map((identifier : string) => datasetsBeingFetched[identifier])
    .filter(notUndefined)

  await Promise.all(datasetsBeingProcessedPromises)

  // in the meantime the store might have changed, so we have to get the identifiers again
  const caasIdentifiersToFetch : string[] = getCaasIdentifiersNotInStore(datasetIds)
    .filter((identifier : string) => !datasetsBeingFetched[identifier])

  if (caasIdentifiersToFetch.length > 0) {
    const payload : { schema : 'global' | 'local', caasIdentifiers : string[] } = { schema: datasetSchema, caasIdentifiers: caasIdentifiersToFetch }
    const promise : Promise<void> = StoreSingleton.instance.dispatch('RemoteDatasetStore/fetchDatasets', payload)

    // add the promise for each identifier to the queue, so if another fetch wants to access the data we can wait for it
    caasIdentifiersToFetch.forEach((identifier : string) => {
      datasetsBeingFetched[identifier] = promise
    })

    // wait until datasets are fetched and placed into store
    await promise

    // delete the entries, which were previously added to the queue (for current fetch - there can be multiple - 'async')
    caasIdentifiersToFetch.forEach((identifier : string) => {
      delete datasetsBeingFetched[identifier]
    })
  }
  return getDatasetsFromStore(datasetIds)
}

const fetchRemoteDatasets = async (schema : 'global' | 'local', caasIdentifiers : string[]) : Promise<Dataset[]> => {
  try {
    const api : FSXAProxyApi = schema === 'global' ? fsxaProxyApiRemote : fsxaProxyApiLocal
    const fetchResponse : FetchResponse = await api.fetchByFilter({
      filters: [
        {
          field: '_id',
          operator: ComparisonQueryOperatorEnum.IN,
          value: caasIdentifiers,
        },
      ],
      pagesize: 100,
      normalized: true,
    })

    const datasets : Dataset[] = fetchResponse.items as Dataset[] || []
    datasets.forEach((dataset : Dataset) => applyPageRefMappingToRemoteDataset(dataset as Dataset, getPageRefMappingFromStore()))
    return datasets
  } catch (error) {
    Log.error(remoteDataset, func('fetchRemoteDatasets'), 'Failed to fetch datasets.', error)
    return []
  }
}

export default getOrFetchRemoteDatasets
export { getRemoteDatasetsFromStore, fetchRemoteDatasets }
